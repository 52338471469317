import SimpleContainer from "./container/container";

function App() {
  return (
    <div className="App">
      <SimpleContainer />
    </div>
  );
}

export default App;
