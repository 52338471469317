import * as React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import fetch from "node-fetch";
import Container from "@mui/material/Container";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import RecyclingIcon from "@mui/icons-material/Recycling";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import CircularProgress from "@mui/material/CircularProgress";
import logo from "../assets/bin-logo.png";
import garbageBin from "../assets/garbage.png";
import compostBin from "../assets/garbage-compost.png";
import recycleBin from "../assets/recycle-bin.png";
import glassBin from "../assets/glass-bin.png";
import defaultBin from "../assets/dust-bin.png";
import sadBin from "../assets/sad.png";

export default function SimpleContainer() {
  const [data, setData] = React.useState(null); // State to hold fetched data
  const [postCode, setPostcode] = React.useState(""); // State for TextField value
  const [houseNum, setHouseNum] = React.useState(""); // State for TextField value
  const [loading, setLoading] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setLoading(false);
    setData(null);
  };

  const encodedPostCode = encodeURIComponent(postCode.trim());

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 500,
    bgcolor: "background.paper",
    border: "1px #ccc6c6",
    borderRadius: "16px",
    boxShadow: 24,
    p: 4,
  };

  const fetchBinSchedule = async () => {
    setLoading(true); // Start loading
    try {
      const response = await fetch(
        `https://8emx52k1l7.execute-api.eu-west-1.amazonaws.com/Prod/get-details?postCode=${encodedPostCode}&houseNum=${houseNum}`
      );
      const data = await response.json();
      setData(data); // Update state with fetched data
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false); // Stop loading
    }
  };

  //   React.useEffect(() => {
  //     fetchBinSchedule(); // Fetch data when component mounts
  //   }, []);

  const handleInputChange = (event) => {
    const { name, value } = event.target; // Destructure name and value
    if (name === "postCode") {
      setPostcode(value); // Update postcode state
    } else if (name === "houseNum") {
      setHouseNum(value); // Update houseNum state
    }
  };

  return (
    <React.Fragment>
      <CssBaseline />
      <Container maxWidth="sm">
        <Box
          sx={{
            bgcolor: "#fff",
            height: "100vh",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center", // Centers vertically
            alignItems: "center", // Centers horizontally,
          }}
        >
          <img
            src={logo}
            alt="Company Logo"
            style={{
              width: "200px", // Adjust the size
              height: "auto",
              marginBottom: "20px", // Add spacing between logo and inputs
            }}
          />
          <TextField
            id="outlined-basic"
            label="Enter Postcode"
            variant="outlined"
            placeholder="BT11 0JZ"
            name="postCode"
            value={postCode} // Controlled input
            onChange={handleInputChange} // Update state on input
            sx={{ marginBottom: "16px" }}
          />
          <TextField
            id="outlined-basic"
            label="Enter House Number"
            variant="outlined"
            placeholder="20"
            name="houseNum"
            value={houseNum} // Controlled input
            onChange={handleInputChange} // Update state on input
          />
          <Button
            variant="outlined"
            sx={{
              marginTop: "16px",
              borderColor: "#c4c4c4",
              color: "#6e6e6e",
              fontWeight: "bold",
            }}
            startIcon={<RecyclingIcon />}
            onClick={() => {
              //   alert("clicked");
              fetchBinSchedule();
              handleOpen();
            }}
          >
            Get Schedule
          </Button>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box
              sx={{
                ...style,
                width: { xs: "90%", sm: "60%", md: "40%" }, // Set responsive widths
                maxWidth: "500px", // Add a max width for larger screens
                margin: "0 auto", // Center the modal horizontally
                padding: 2, // Add some padding for better appearance
              }}
            >
              {loading ? (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                  }}
                >
                  <CircularProgress />
                </Box>
              ) : data ? (
                <>
                  <Typography
                    id="modal-modal-title"
                    variant="h6"
                    component="h2"
                  >
                    {data.error ? (
                      "Error"
                    ) : data.length < 1 ? (
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <img
                          src={sadBin}
                          style={{
                            width: "60px",
                            height: "60px",
                            marginRight: "8px",
                          }}
                          alt="Sad bin"
                        />
                        <h5 style={{ margin: 0 }}>
                          Awwh rubbish!! can't find your schedule
                        </h5>
                      </div>
                    ) : null}
                  </Typography>
                  <Box id="modal-modal-description" sx={{ mt: 2 }}>
                    {data.error ? (
                      <Typography>{data.error}</Typography>
                    ) : (
                      <ul>
                        {data.map((item, index) => (
                          <li
                            key={`${item.binType}-${index}`}
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginBottom: "10px",
                            }}
                          >
                            {getIcon(item.binType)}
                            <Typography sx={{ ml: 1 }}>
                              {item.binType}: {item.nextCollection}
                            </Typography>
                          </li>
                        ))}
                      </ul>
                    )}
                  </Box>
                </>
              ) : null}
            </Box>
          </Modal>
        </Box>
      </Container>
    </React.Fragment>
  );
}

const getIcon = (binType) => {
  if (binType.includes("Glass")) {
    return (
      <img
        src={glassBin}
        style={{ width: "40px", height: "40px", marginRight: "8px" }}
      />
    );
  }
  if (binType.includes("General")) {
    return (
      <img
        src={garbageBin}
        style={{ width: "40px", height: "40px", marginRight: "8px" }}
      />
    );
  }

  if (binType.includes("Recycling")) {
    return (
      <img
        src={recycleBin}
        style={{ width: "40px", height: "40px", marginRight: "8px" }}
      />
    );
  }

  if (binType.includes("Compost")) {
    return (
      <img
        src={compostBin}
        style={{ width: "40px", height: "40px", marginRight: "8px" }}
      />
    );
  }
  // Default case
  return (
    <img
      src={defaultBin}
      style={{ width: "40px", height: "40px", marginRight: "8px" }}
    />
  );
};
